<template>
	<div>
		<ContentHeader title="Analisis Traffic" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
          <div class="card-header">
            <h3 class="card-title"><div style="#007bff">Daftar Statistik</div></h3>
          </div>
          <div class="card-body">
          	<div class="row">
        		  <div class="col-md-3">
		            <div class="card card-outline card-primary">
		              <div class="card-header">
        						<router-link to="/analisis-kunjungan-pasien" class="cursor-pointer">
		                	<h3 class="card-title">Kunjungan Pasien</h3>
        						</router-link>		
		              </div>
		            </div>
		          </div>		
		          <div class="col-md-3">
		            <div class="card card-outline card-primary">
		              <div class="card-header">
		              	<router-link to="/analisis-konsultasi-dokter" class="cursor-pointer">
		                	<h3 class="card-title">Konsultasi Dokter</h3>
        						</router-link>
		              </div>
		            </div>
		          </div>
		        </div>
          </div>
      	</div>
			</div>
		</div>
	</div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader'

export default {
	components: {
		ContentHeader
	}
}
</script>